<template>
	<div class="name">
		<span>{{ show_name }}</span>
	</div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
	name: String,
	length: {
		type: Number,
	},
});

// 钉钉显示人名
const show_name = computed(() => {
	if (props.length && props.name) {
		return props.name.slice(0, props.length);
	}
	return props.name;
});
</script>

<style lang="less" scoped>
.name {
	font-size: inherit;
	span {
		white-space: nowrap;
	}
}
</style>
