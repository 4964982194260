import Vue, { computed, ref } from "vue";
import selectUserDeptComp from "./index.vue";
import api from "@/api/methods/index.js";
import { Message } from "element-ui";

export const $selectUserDept = (options = {}) => {
	options.onHide = () => {
		document.body.removeChild(instance.vm.$el);
	};

	const Person = Vue.extend(selectUserDeptComp);
	const instance = new Person();
	instance.vm = instance.$mount();
	document.body.appendChild(instance.vm.$el);
	instance.init(options);
};

export const useUserDept = (props) => {
	console.log("props", props);
	// 根部门
	const rootDept = ref({});
	// 部门列表 (key:上级dept_id value:部门列表)
	const depts = ref({});
	// 人员列表 (key:上级dept_id  value:部门下的人员列表)
	const users = ref({});

	// 当前选择的部门
	const nowDept = ref({});
	// 选中的部门/人员列表
	const selectedList = ref([]);

	// 是否有选择人员
	const needSelectUser = computed(() => props.value.type.includes("user"));
	// 是否有选择部门
	const needSelectDept = computed(() => props.value.type.includes("dept"));

	// 根据父级部门id获取子部门
	const getId2Dept = async (dept_id) => {
		if (depts.value[dept_id] != undefined) return;
		const msg = await api.user.GetChildDepartmentInfo({ dept_id });
		if (msg.data.state) {
			depts.value = { ...depts.value, [dept_id]: msg.data.list || [] };
			console.log("depts", depts.value);
		}
	};
	// 根据父级部门id获取人员
	const getId2User = async (dept_id) => {
		if (users.value[dept_id] != undefined) return;
		const msg = await api.user.GetUsersByDeptId({ dept_id });
		if (msg.data.state) {
			users.value = { ...users.value, [dept_id]: msg.data.list || [] };
			console.log("depts", users.value);
		}
	};
	// 选中部门
	const changeNowDept = (dept = {}) => {
		nowDept.value = dept;
		getId2Dept(dept.department_id);
		getId2User(dept.department_id);
	};
	// 获取根部门
	const getRootDept = async () => {
		const msg = await api.user.GetChildDepartmentInfo();
		if (msg.data.state) {
			rootDept.value = msg.data.list[0];
			console.log("rootDept", rootDept.value);
			changeNowDept(rootDept.value);
		}
	};
	getRootDept();

	/** 展示内容 */
	// 获取部门的子部门
	const getDept = (dept_id) => {
		return depts.value[dept_id];
	};
	// 获取部门的成员
	const getUsers = (dept_id) => {
		return users.value[dept_id];
	};
	// 获取面包屑
	const allDepts = computed(() => Object.values(depts.value).flat(1));
	const getBreadCrumbs = (dept_id) => {
		if (dept_id == rootDept.value.department_id) return [];
		const dept = allDepts.value.find((it) => it.department_id == dept_id);
		return [...getBreadCrumbs(dept.parent_id), dept];
	};

	// 是否是部门
	const isDept = (user_or_dept) => {
		return !!user_or_dept.department_id;
	};
	// 选中/取消 部门/人员
	const select = (user_or_dept) => {
		// 只能选择部门
		if (needSelectDept.value && !isDept(user_or_dept)) return;
		// 只能选人
		if (needSelectUser.value && isDept(user_or_dept)) return;

		if (isSelected(user_or_dept)) {
			if (needSelectDept.value) {
				const index = selectedList.value.findIndex(
					(it) => it.department_id == user_or_dept.department_id
				);
				selectedList.value.splice(index, 1);
			}
			if (needSelectUser.value) {
				const index = selectedList.value.findIndex(
					(it) => it.userid == user_or_dept.userid
				);
				selectedList.value.splice(index, 1);
			}
		} else {
			if (props.value.size == 1) {
				selectedList.value = [user_or_dept];
			}
			if (selectedList.value.length < props.value.size) {
				selectedList.value.push(user_or_dept);
			}
		}
		console.log("selectedList", selectedList.value);
	};
	// 是否选中部门/人员
	const isSelected = (user_or_dept) => {
		if (needSelectDept.value) {
			return !!selectedList.value.find(
				(it) => it.department_id == user_or_dept.department_id
			);
		}
		if (needSelectUser.value) {
			return !!selectedList.value.find((it) => it.userid == user_or_dept.userid);
		}
	};

	// 是否显示全选
	const show_all_select = (dept_id) => {
		if (needSelectDept.value && depts.value[dept_id]?.length) {
			return true;
		}
		if (needSelectUser.value && users.value[dept_id]?.length) {
			return true;
		}
		return false;
	};
	// 全选
	const allSelected = (dept_id) => {
		// 取消全选
		if (isAllSelected(dept_id)) {
			if (needSelectDept.value) {
				depts.value[dept_id].forEach((it) => select(it));
			}
			if (needSelectUser.value) {
				users.value[dept_id].forEach((it) => select(it));
			}
		} else {
			// 全部选中
			if (needSelectDept.value) {
				// 超出限制
				if (
					selectedList.value.length + depts.value[dept_id].length >
					props.value.size
				) {
					Message({
						type: "success",
						message: "全选超出数量限制，请手动选择",
					});
					return;
				}

				depts.value[dept_id].forEach((it) => {
					if (!isSelected(it)) {
						select(it);
					}
				});
			}
			if (needSelectUser.value) {
				// 超出限制
				if (
					selectedList.value.length + users.value[dept_id].length >
					props.value.size
				) {
					Message({
						type: "success",
						message: "全选超出数量限制，请手动选择",
					});
					return;
				}
				users.value[dept_id].forEach((it) => {
					if (!isSelected(it)) {
						select(it);
					}
				});
			}
		}
	};
	// 是否全选
	const isAllSelected = (dept_id) => {
		const deptAllSelected = needSelectDept.value
			? depts.value[dept_id]?.every?.((it) => isSelected(it))
			: true;

		const userAllSelected = needSelectUser.value
			? users.value[dept_id]?.every?.((it) => isSelected(it))
			: true;

		return deptAllSelected && userAllSelected;
	};

	return {
		rootDept,
		depts,
		users,
		nowDept,
		selectedList,
		needSelectUser,
		needSelectDept,
		changeNowDept,
		getId2Dept,
		getId2User,
		getDept,
		getUsers,
		allDepts,
		getBreadCrumbs,
		select,
		isSelected,
		show_all_select,
		allSelected,
		isAllSelected,
	};
};
