<template>
	<div class="avatar" :style="{ '--size': size + 'px' }">
		<img :src="avatar" v-if="avatar" />
		<name_comp v-else :name="name" :length="2" />
	</div>
</template>

<script setup>
import { computed } from "vue";
import name_comp from "./name.vue";

const props = defineProps({
	avatar: String,
	round: {
		type: Boolean,
		default: true,
	},
	name: String,
	size: {
		type: Number,
		default: 40,
	},
});

const borderRadius = computed(() => {
	return props.round ? "50%" : "6px";
});
</script>

<style lang="less" scoped>
.avatar {
	width: var(--size);
	height: var(--size);
	border-radius: v-bind(borderRadius);
	background-color: #28c485;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		cursor: pointer;
	}
}
</style>
