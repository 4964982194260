import { $selectUserDept } from "@/components/select_people/index.js";
import container from "@/utils/container.js";
import api from "@/api/methods/index.js";
/**
 * 钉钉选人
 */
import app from "@/utils/ddready";
import store from "@/vuex/index";
export default {
	peoplePicker: async function(
		users,
		callback,
		multiple = false,
		maxUsers = 1000
	) {
		if (container.dd) {
			dd.ready(function() {
				dd.biz.contact.complexPicker({
					title: "人员选择", //标题
					corpId: store.state.user.corp.corp_id, //企业的corpId
					multiple: multiple, //是否多选
					limitTips: "超出了", //超过限定人数返回提示
					maxUsers: maxUsers, //最大可选人数
					pickedUsers: users, //已选用户Array
					pickedDepartments: [], //已选部门
					disabledUsers: [], //不可选用户
					disabledDepartments: [], //不可选部门
					requiredUsers: [], //必选用户（不可取消选中状态）
					requiredDepartments: [], //必选部门（不可取消选中状态）
					appId: store.state.user.corp.app_id, //微应用的Id
					permissionType: "GLOBAL", //选人权限，目前只有GLOBAL这个参数
					responseUserOnly: true, //返回人，或者返回人和部门
					onSuccess: function(result) {
						/**
                         {
                        selectedCount:1,                              //选择人数
                        users:[{"name":"","avatar":"","emplId":""}]，//返回选人的列表，列表中的对象包含name（用户名），avatar（用户头像），emplId（用户工号）三个字段
                        departments:[{"id":,"name":"","number":}]//返回已选部门列表，列表中每个对象包含id（部门id）、name（部门名称）、number（部门人数）
                    }
                         */
						callback(result);
					},
					onFail: function(err) {
						//错误的话报错
						if (err.errorCode != -1) {
							if (typeof err == "object") {
								err["corpId"] = store.state.user.corp.corp_id;
							}
							console.log(
								JSON.stringify({
									log_name: "选人错误",
									log_data: { corpId: store.state.user.corp.corp_id },
									sendmsg: err,
								})
							);
							app.ready_login(function() {});
						}
					},
				});
			});
		} else if (container.browser) {
			let user_info = [];
			if (users.length) {
				const result = await api.user.GetInfoByIds({
					ids: users.join(","),
					type: 1,
				});
				if (result.data.state == 1) {
					user_info = result.data.list;
				}
			}

			$selectUserDept({
				value: user_info,
				callBack: (data) => {
					console.log("data", data);
					const users = data.map((it) => ({
						avatar: it.avatar,
						name: it.nickname,
						emplId: it.userid,
					}));
					callback({
						users,
					});
				},
				size: multiple ? maxUsers : 1,
			});
		}
	},
	departmentPicker: async function(department, callback) {
		if (container.dd) {
			dd.ready(function() {
				dd.biz.contact.departmentsPicker({
					title: "部门选择", //标题
					corpId: store.state.user.corp.corp_id, //企业的corpId
					multiple: false, //是否多选
					limitTips: "超出了", //超过限定人数返回提示
					maxDepartments: 100, //最大选择部门数量
					pickedDepartments: department, //已选部门Array
					disabledDepartments: [], //不可选部门
					requiredDepartments: [], //必选部门（不可取消选中状态）
					appId: store.state.user.corp.app_id, //微应用的Id
					permissionType: "GLOBAL", //选人权限，目前只有GLOBAL这个参数
					onSuccess: function(result) {
						/**
                     {
                        userCount:1,                              //选择人数
                        departmentsCount:1，//选择的部门数量
                        departments:[{"id":,"name":"","number":}]//返回已选部门列表，列表中每个对象包含id（部门id）、name（部门名称）、number（部门人数）
                    }
                     */
						callback(result);
					},
					onFail: function(err) {
						//错误的话报错
						if (err.errorCode != -1) {
							if (typeof err == "object") {
								err["corpId"] = store.state.user.corp.corp_id;
							}
							console.log(
								JSON.stringify({
									log_name: "选部门错误",
									log_data: { corpId: store.state.user.corp.corp_id },
									sendmsg: err,
								})
							);
							app.ready_login(function() {});
						}
					},
				});
			});
		} else if (container.browser) {
			let dept_info = [];
			if (department.length) {
				const result = await api.user.GetInfoByIds({
					ids: department.join(","),
					type: 2,
				});
				if (result.data.state == 1) {
					dept_info = result.data.list;
				}
			}

			$selectUserDept({
				value: dept_info,
				type: ["dept"],
				size: 1,
				callBack: (data) => {
					const departments = data.map((it) => ({
						id: it.department_id,
						name: it.name,
					}));
					callback({
						departments,
					});
				},
			});
		}
	},
};
