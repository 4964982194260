<template>
	<div id="select_user_dept_mask">
		<div class="user_dept_content">
			<header>
				<span class="title">人员/部门选择</span>
				<i class="el-icon-close close" @click="close"></i>
			</header>

			<div class="content">
				<!-- 左侧列表 -->
				<div class="left">
					<!-- 选中列表 -->
					<div class="name_list">
						<template v-if="selectedList.length">
							<div class="user" v-for="it in selectedList">
								<name_comp :name="it.name || it.nickname" />
								<i class="el-icon-error" @click="select(it)"></i>
							</div>
						</template>

						<!-- 没有任何选中项时显示的默认项 -->
						<template v-else-if="props.show_default_text">
							<div class="user">
								<span>{{ props.show_default_text }}</span>
							</div>
						</template>
					</div>

					<!-- 操作 -->
					<div class="options">
						<Button size="small" @click="close">取消</Button>
						<Button size="small" type="success" @click="cnofirm">
							确认({{ selectedList.length }}/{{ props.size }})
						</Button>
					</div>
				</div>

				<!-- 右侧列表 -->
				<div class="right">
					<!-- 面包屑 -->
					<div class="bread_crumbs">
						<!-- 公司名 -->
						<span class="title">上海援青项目信息化管理平台</span>

						<!-- 面包屑 -->
						<div class="dept_list">
							<span class="company_name" @click="changeNowDept(rootDept)">
								{{ rootDept.name }}
							</span>
							<div
								class="dept_item"
								v-for="it in getBreadCrumbs(nowDept.department_id)"
								:key="it.department_id"
								@click="changeNowDept(it)"
							>
								<span class="split_line">/</span>
								<span class="dept">{{ it.name }}</span>
							</div>
						</div>
					</div>

					<!-- 人员/部门列表 -->
					<div class="user_dept_list">
						<div
							class="item dept"
							@click.prevent="allSelected(nowDept.department_id)"
							v-if="show_all_select(nowDept.department_id)"
						>
							<Checkbox :value="isAllSelected(nowDept.department_id)" />
							<span class="name">全选</span>
						</div>

						<!-- 部门 -->
						<div
							class="item dept"
							v-for="dept in getDept(nowDept.department_id)"
							:key="dept.department_id"
							@click.prevent="select(dept)"
						>
							<Checkbox :value="isSelected(dept)" v-if="needSelectDept" />
							<name_comp
								:name="dept.name"
								:style="{ marginLeft: needSelectDept ? '' : '0px' }"
							/>
							<div
								class="subordinate"
								:class="{ disabled: isSelected(dept) }"
								@click.stop="!isSelected(dept) && changeNowDept(dept)"
							>
								<span>下级</span>
							</div>
						</div>

						<!-- 人员 -->
						<div
							v-if="needSelectUser"
							class="item user"
							v-for="user in getUsers(nowDept.department_id)"
							:key="user.userid"
							@click.prevent="select(user)"
						>
							<Checkbox :value="isSelected(user)" />
							<div class="avatar_name">
								<avatar_comp :name="user.nickname" :avatar="user.avatar" :size="34" />
								<name_comp :name="user.nickname" :style="{ marginLeft: '8px' }" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineExpose, ref } from "vue";
import { useUserDept } from "./index";
import { Checkbox, Button } from "element-ui";
import avatar_comp from "@/components/avatar/avatar.vue";
import name_comp from "@/components/avatar/name.vue";

const props = ref({
	value: [],
	type: ["user"], // ['user','dept']
	size: 1000,
	callBack: () => {},
	show_default_text: "", // 默认显示项
});

const {
	rootDept,
	nowDept,
	selectedList,
	needSelectUser,
	needSelectDept,
	changeNowDept,
	getDept,
	getUsers,
	getBreadCrumbs,
	select,
	isSelected,
	show_all_select,
	allSelected,
	isAllSelected,
} = useUserDept(props);

// 关闭
const close = () => {
	props.value.onHide();
};
// 确认
const cnofirm = () => {
	props.value.callBack(selectedList.value);
	close();
};

const init = (options) => {
	props.value = {
		...props.value,
		...options,
	};
	selectedList.value = JSON.parse(JSON.stringify(props.value.value));
};

defineExpose({
	init,
});
</script>

<style lang="less" scoped>
#select_user_dept_mask {
	--text-light-more-color: #999;
	--color: #00b192;
	--hover-bg-color: #e6f8f5;
	--text-light-color: #666;
	--bg-gray: #f4f6f6;
	--border-radium-small: 3px;
	--border-radium-medium: 5px;
	--border-radium-large: 10px;

	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 10000;

	.user_dept_content {
		position: absolute;
		width: 800px;
		height: 600px;
		background-color: white;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: var(--border-radium-large);
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		overflow: hidden;

		header {
			flex-shrink: 0;
			height: 60px;
			border-bottom: 1px solid #e9ebeb;
			display: flex;
			align-items: center;
			padding: 0 20px;
			box-sizing: border-box;
			justify-content: space-between;

			.close {
				font-size: 20px;
				cursor: pointer;
			}
		}

		.content {
			display: flex;
			justify-content: space-between;
			padding: 0 20px;
			box-sizing: border-box;
			flex: 1;
			overflow: hidden;

			.left {
				flex: 1;
				margin: 20px 20px 20px 0;
				box-sizing: border-box;
				border: 1px dashed #dfdfdf;
				border-radius: var(--border-radium-large);
				padding: 16px;
				display: flex;
				flex-direction: column;

				.search {
					background-color: var(--bg-gray);
					height: 36px;
					display: flex;
					align-items: center;
					padding: 0 0 0 13px;
					box-sizing: border-box;
					border-radius: var(--border-radium-medium);
					flex-shrink: 0;
					position: relative;

					i {
						color: var(--color);
						font-weight: bold;
						margin-right: 10px;
					}
					.clear {
						transform: rotate(45deg);
						border-radius: 50%;
						padding: 2px;
						cursor: pointer;
					}

					input {
						background-color: transparent;
						flex: 1;
						margin-right: 10px;
					}

					.search_list {
						position: absolute;
						width: 100%;
						min-height: 100px;
						max-height: 200px;
						top: 120%;
						left: 0;
						background-color: white;
						display: flex;
						flex-direction: column;
						overflow: auto;
						box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
						border-radius: var(--border-radium-medium);

						&::-webkit-scrollbar {
							width: 4px;
						}

						.item {
							padding: 4px 10px;
							cursor: pointer;
							display: flex;
							align-items: center;

							&:hover {
								background-color: var(--hover-bg-color);
							}
							> div {
								display: flex;
								align-items: center;
								flex: 1;
							}
							.icon-66 {
								display: none;
							}

							&.active {
								color: var(--color);
								.icon-66 {
									display: inline-flex;
								}
							}
						}

						.noMore {
							text-align: center;
							margin: 4px 0;
							color: rgb(197, 197, 197);
							font-size: 12px;
						}
						.noData {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}

				.name_list {
					flex: 1;
					display: flex;
					flex-wrap: wrap;
					overflow: auto;
					align-content: flex-start;
					margin-top: 10px;
					&::-webkit-scrollbar {
						width: 4px;
					}

					.user {
						display: flex;
						align-items: center;
						height: 30px;
						background-color: var(--bg-gray);
						padding: 0 11px;
						box-sizing: border-box;
						border-radius: var(--border-radium-small);
						margin-bottom: 10px;
						margin-right: 10px;

						i {
							margin-left: 4px;
							font-size: 14px;
							color: var(--text-light-more-color);
							cursor: pointer;

							&:hover {
								color: var(--color);
							}
						}
					}
				}

				.options {
					display: flex;
					justify-content: flex-end;
					margin-top: 10px;
					flex-shrink: 0;
				}
			}

			.right {
				width: 370px;
				box-sizing: border-box;
				padding: 26px 0;
				display: flex;
				flex-direction: column;
				overflow: hidden;

				.bread_crumbs {
					border-bottom: 1px solid #eeeeee;
					padding-bottom: 20px;
					box-sizing: border-box;

					.title {
						font-size: 16px;
						display: flex;
						align-items: center;
					}

					.dept_list {
						margin-top: 4px;
						display: flex;
						flex-wrap: wrap;
						color: var(--text-light-more-color);

						.company_name {
							cursor: pointer;

							&:hover {
								color: var(--color);
							}
						}

						.dept_item {
							.split_line {
								margin: 0 4px;
							}

							.dept {
								cursor: pointer;
								&:hover {
									color: var(--color);
								}
							}
						}
						.dept_item:last-child {
							color: black;
						}
					}
				}

				.user_dept_list {
					flex: 1;
					overflow-y: auto;
					margin-top: 10px;
					display: flex;
					flex-direction: column;
					padding-right: 10px;

					&::-webkit-scrollbar {
						width: 4px;
					}

					.item {
						display: flex;
						align-items: center;
						cursor: pointer;
						flex-shrink: 0;

						&:hover {
							background-color: var(--hover-bg-color);
						}
					}

					.dept {
						height: 32px;

						.name {
							flex: 1;
							margin: 0 10px;
						}

						.subordinate {
							display: flex;
							align-items: center;
							color: var(--color);

							&.disabled {
								color: var(--text-light-color);
								opacity: 0.6;
							}

							span {
								margin-left: 4px;
							}
						}
					}

					.user {
						display: flex;
						align-items: center;
						margin: 4px 0;
						padding: 4px 0;
						box-sizing: border-box;

						.avatar_name {
							display: flex;
							align-items: center;
							margin-left: 10px;
						}
					}
				}
			}
		}
	}

	::v-deep(.el-button--success) {
		background-color: var(--color);
	}
	::v-deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
		background-color: var(--color) !important;
		border-color: var(--color) !important;
	}
}
</style>
